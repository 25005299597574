/* eslint-disable jsx-a11y/alt-text */
import React, { useState,useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import { getCovidGLDataAction } from "../../../../actions/tcDataAction";
import {postBookAppointmentAction} from "../../../../actions/appointmentAction";
import AddressAddModal from  "../../../my_account/Address/AddressAddModal";
import {useCountryCurrency} from "../../../../helpers/useCountryCurrency";
import {hasStripePayment} from "../../../../helpers/helpers";
import {BuyOnline} from "../../../../helpers/bookingAPIsteps";
import {toast} from "react-toastify";

const moment = require("moment");

const NextBackButton = ({ checkoutData, cart, updateCheckoutData, confirmAppointmentPay,  addressInformation, loginSignupRef, error, setCheckoutData}) => {
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const {country_list} = getAllCountryState;
  const getCovidGLState = useSelector(state => state.getTCReducer)
  const { loading, covid_guidlines } = getCovidGLState;
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/staticPage/info/covid";
  const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';
  const currentUser = (localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "null") ? JSON.parse(localStorage.getItem('currentUser')) : false;
  const setAddAddressRef = useRef();
  const countryCurrency = useCountryCurrency(
    localStorage.getItem("userCountryId")
  );
  const currency = countryCurrency?.length > 0
    ? countryCurrency[0]?.currency
    : localStorage.getItem("currency");

  useEffect(() => {
      dispatch(getCovidGLDataAction(listUrl,{}));
  }, [listUrl,dispatch]);
 
  const [bookingSummaryInfoShow, setBookingSummaryInfoShow] = useState(false);
  const handleSetBookingSummaryInfoShow = () => setBookingSummaryInfoShow(true);
  const handlesetBookingSummaryInfoHide = () => setBookingSummaryInfoShow(false);

  const [covidGLInfoShow, setCovidGLInfoShow] = useState(false);
  const handleSetCovidGLInfoShow = () => setCovidGLInfoShow(true);
  const handleSetCovidGLInfoHide = () => setCovidGLInfoShow(false);
  const getCleanerTimeAvailabilitySlot = useSelector(
    (state) => state.cleanerTimeAvailabilityReducer
  );
  const { available_time, selectedCleaner  } = getCleanerTimeAvailabilitySlot;  
  let values111={ 
    address_id: checkoutData.address_id,
    is_home_cleaning: checkoutData.is_home_cleaning,
    service_id: checkoutData.service_id,
    country_id:localStorage.getItem('userCountryId'),
     customer_id: currentUser?._id
  };
  function changeStep(e,step,page) {
   e.preventDefault();
   var updateStep = checkoutData.page;
    let appointmentData = {}
    if(step === "Next" && page){
    updateStep = (checkoutData.page === 1.5)? 1: checkoutData.page;
    page = (page === 1.5) ? 1: page

      appointmentData = {
        1:{ordered_item: checkoutData?.cart,
      service_category_id: [checkoutData?.cart[0]?.sub_service_id],
      service_package_id: [checkoutData?.cart[0]?._id],
      appointment_process: 3,
      customer_id: currentUser?._id,
       totalAmount:checkoutData?.totalPrice ,
      },
      2:{appointment_date_start: checkoutData.appointment_date_start,
        start_time_between: checkoutData.start_time_between,
        appointment_process: 3,
        is_auto_assign: "true",
        hours_count: checkoutData.hours_count,
        address_id: checkoutData.address_id,
        cleaner_id : selectedCleaner ? selectedCleaner: ''
      },
      3:{
        payment_mode: checkoutData.payment_mode !== ''? checkoutData.payment_mode :"",
        discount_type: checkoutData.discount_type !== ''? checkoutData.discount_type :"",
        voucher:checkoutData.voucher,
        promo_code:checkoutData.promo_code,
        source_id:checkoutData.source_id,
        customer_id: currentUser?._id,
        pay_type: checkoutData.pay_type !== ''? checkoutData.pay_type :"" ,
        transaction_no :"",
        isWallet: checkoutData.isWallet ? checkoutData.isWallet:"",
        appointment_process: 4,
        appointment_date_start: checkoutData.appointment_date_start
      }
    }
    
    //   // appointmentData[page]['appointment_process'] = page;
    if(currentUser){
      if(page === 1 ){
        if (!checkoutData.actionStatus) {
          updateStep=1.5
          addressInformation(values111);
        }
      
        setTimeout(()=>{
          appointmentData[page]['appointment_id'] = localStorage.getItem('bookAppointmentId') && localStorage.getItem('bookAppointmentId') !== undefined ? localStorage.getItem('bookAppointmentId') : (
            checkoutData?.appointment_id ? checkoutData.appointment_id: ""
          );
          confirmAppointmentPay(appointmentData[page]);

        },5000);
      } else {
        if (page > 3) {page =3};
        appointmentData[page]['appointment_id'] = localStorage.getItem('bookAppointmentId') && localStorage.getItem('bookAppointmentId') !== undefined ? localStorage.getItem('bookAppointmentId') : (
           checkoutData?.appointment_id ? checkoutData.appointment_id: ""
        );
        confirmAppointmentPay(appointmentData[page]);
      }
    }
  }
 
    if(step === "Next" ){

      if(page === 2 && currentUser){
       
          // handleSetCovidGLInfoShow();
      }
      
      if(checkoutData.actionStatus && currentUser) {  
       updateStep = updateStep <= 3 ? updateStep + 1 : updateStep;
       } else {
         updateStep = updateStep >= 4 ? updateStep + 1 : updateStep;
       }
       if(currentUser === false) {
        updateStep = updateStep <= 3 ? updateStep + 1 : updateStep;
       }
      if(updateStep >= 4){
          confirmAppointmentPay(appointmentData[3]);
      }
    }else{
      if(checkoutData.page === 5){setCheckoutData({...checkoutData, ['payment_mode']:''})}
      if (checkoutData.page === 2) {
        updateStep = 1.5
      } else if (checkoutData.page === 1.5) {
        updateStep = 1
      } else { 
        updateStep = updateStep - 1
      }
     
    } 

    updateCheckoutData('page',updateStep);
    const url = new URL(window.location.href);
    url.searchParams.set('step', updateStep);
    window.history.replaceState(null, null, url);
  }

  const handelPayOnline = async () => {
    let price = parseFloat(checkoutData?.totalPrice) || 0;
    // Use Number() instead of conditional operator to handle non-numeric values
    price +=
      Number(checkoutData?.CodCharges) > 0
        ? Number(checkoutData.CodCharges)
        : 0;
    price +=
      Number(checkoutData?.rushSlot) > 0 ? Number(checkoutData.rushSlot) : 0;
    price -=
      Number(checkoutData?.total_discount) > 0 && Number(checkoutData?.page) > 5
        ? Number(checkoutData.total_discount)
        : 0;
    price +=
      checkoutData?.isBuyMemberShipRadio === true
        ? countryCurrency[0]?.rhPrice
        : 0;
    price +=
      checkoutData?.holidayPrice > 0 ? Number(checkoutData.holidayPrice) : 0;
    price -=
      checkoutData?.voucherDiscount > 0
        ? Number(checkoutData.voucherDiscount)
        : 0;
    price -=
      checkoutData?.rh_discount > 0 ? Number(checkoutData.rh_discount) : 0;

    if (checkoutData?.wallet_amount > 0 && checkoutData?.isWallet) {
      price = checkoutData?.isWallet
        ? Math.max(price - Number(checkoutData?.deducted_amount), 0)
        : price;
    }
    let onlinePayUrl = `${baseUrl}/appointment/generate/payment/link/`;

    // Convert the object to a JSON string
    const checkoutDataString = JSON.stringify(checkoutData);
    localStorage.setItem("checkoutData", checkoutDataString);
    const data = {
      amount: price,
      name: currentUser?.name,
      phone: `${currentUser?.country_code}${currentUser?.mobile}`,
      email: currentUser.email,
      reference_no: checkoutData?.appointment_no,
      custom: checkoutData?.isBuyMemberShipRadio
        ? "memberShipAppointment"
        : "appointment",
      tokenId: checkoutData?.tokenId ? checkoutData?.tokenId : "",
      isStripe: hasStripePayment(checkoutData?.country_id, country_list)
        ? true
        : false
    };
    try {
      const stepOneData = await BuyOnline({
        baseUrl: onlinePayUrl,
        data
      });
      if (stepOneData.paymentRequire === false) {
        window.location.href = stepOneData?.data?.successUrl;
      } else if (stepOneData?.data) {
        window.location.href = stepOneData?.data?.payUrl;
      }
    } catch (error) {
      // Handle errors here
      toast.error(error, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      console.error("stepOneData-error", error);
    }
  };

  
  const { t } = useTranslation();
  let minOrderError = t('min_order_amount_error')
  let amount = currency+"  "+checkoutData?.remainigMinOrderAmount
  const formattedMinOrderError = minOrderError.replace('${amount}', amount);
  let flexAlign = checkoutData?.remainigMinOrderAmount > 0 ? 'actionButton leftMe' : 'actionButton rightMe';
  return (
    <>
      <div className="row servicePackage">
        <div className="col-lg-7">
          <div className={flexAlign}>
            <button
              disabled={checkoutData.page === 1}
              className=" btn-back"
              onClick={(e) => changeStep(e,"Previous",checkoutData.page)}
            >
              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/back.png.webp" />
            </button>
            <div className="priceTotal">
              <p>{t('total')}</p> 
              <h3>
                {checkoutData.currency} {(checkoutData && checkoutData.totalPrice) ? checkoutData.totalPrice : 0}
                <span
                  className="fa fa-angle-up ms-2"
                  onClick={() => handleSetBookingSummaryInfoShow()}
                ></span>
              </h3>
            </div>
            {
              (
                () => {
                  if (cart?.length !== 0){
                  if (checkoutData.remainigMinOrderAmount  > 0 ) {
                    return <div class="alert-container">
                    <span class="alert-icon">&#9888;</span>{"   "}
                    <span class="min-order-style">{formattedMinOrderError}</span>
                </div>
                  }}
                }
              )()
            }
            {(() => {
              let showButton = true;
              if (checkoutData.page === 1) {
                if(cart?.length === 0){
                  showButton = false;
                }
                
              }
             
              if(checkoutData.page === 2 && (checkoutData.appointment_date === "" || checkoutData.start_time_between === "" )&& checkoutData.actionStatus){
                showButton = false;
                // if(currentUser && checkoutData.actionStatus) {
                //   showButton =true;
                // } else {
                //   showButton = false;
                // }
                if(currentUser === false && checkoutData.latitude <= 0){
                  setAddAddressRef.current.handleAddress()
                }
                
              }
             
              // if(checkoutData.page === 3 && (!checkoutData.qnb_customer_id || checkoutData.qnb_customer_id === "")){
              //   showButton = false;
              // }
              
              if (checkoutData.page === 3 ) {
                if(currentUser ){
                
                  if(checkoutData.payment_mode !== "" && checkoutData.payment_terms_condition) {
                    showButton =true
                   
                  } else {
                    showButton = false 
                  }
                  
                } else {
                  loginSignupRef.current.handleLoginSignupModalShow();
                }
              
              }

              // if (showButton && checkoutData.remainigMinOrderAmount  <=0) {
              //   return <button
              //     className="btn btn-primary"
              //     onClick={(e) => changeStep(e,"Next",checkoutData.page)}
              //   >
              //     {(checkoutData.page === 3 || checkoutData.page === 4) ? 
                  
              //     t('complete') : t('next')}
              // </button>
              // }

              if (showButton && checkoutData.remainigMinOrderAmount  <=0) {
                return (
                  <>
                    {checkoutData?.payment_terms_condition &&
                    checkoutData?.totalPrice > 0 &&
                    checkoutData.payment_mode === "card" ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => handelPayOnline(checkoutData)}
                      >
                      Complete
                      </button>
                    ) : (
                      // <button
                      //   className="btn btn-primary"
                      //   // onClick={() => changeStep("Next", checkoutData.page)}
                      //   onClick={() => nextButtonClick(checkoutData)}
                      //   disabled={
                      //     checkoutData.page === 4 &&
                      //     checkoutData.start_time_between === ""
                      //       ? true
                      //       : false
                      //   }
                      // >
                      //   {checkoutData.page !== 5 ||
                      //   checkoutData?.wayOfBooking === "withoutLogin"
                      //     ? t("next")
                      //     : t("complete")}
                      // </button>
                      <button
                      className="btn btn-primary"
                      onClick={(e) => changeStep(e,"Next",checkoutData.page)}
                    >
                      {(checkoutData.page === 3 || checkoutData.page === 4) ? 
                      
                      t('complete') : t('next')}
                  </button>
                    )}

                    {/* : 
                  <QnbPayment/>} */}
                  </>
                );
              }
            })()}
          </div>
        </div>
      </div>

      <Modal
        show={covidGLInfoShow}
        onHide={handleSetCovidGLInfoHide}
        id="covidModal"
        backdrop="static"
        className="covidModal"
      >
        <Modal.Header>  
          <Modal.Title>{ t('health_declaration') }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="modal-body p-0 ">
              < img src="https://dvdljkkxpxqo3.cloudfront.net/images/covid.jpg.webp"
                className="w-full "
              />
              <div className="p-4">
                 {covid_guidlines ?
                 <div className="accordion-body" dangerouslySetInnerHTML={ { __html: (currentLanguage === "en" ? covid_guidlines.content : covid_guidlines.content_arabic)}}>
                 </div>
                 : ""}
              </div>
              <div className="p-4 border-top mt-2">
                <button className="btn btn-primary" onClick={() => handleSetCovidGLInfoHide()}>{ t('i_confirm') }</button>
              </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={bookingSummaryInfoShow}
        onHide={handlesetBookingSummaryInfoHide}
        id="bookingSummary"
        className="bookingSummary"
      >
        <Modal.Header closeButton>  
          <Modal.Title>{t('booking_summary')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="modal-body p-0 ">
            <div className="card ">
              <div className="card-content ">
                <p>{t('city')}</p> 
                <p>Duhail</p>
              </div>
              <div className="card-content ">
                <p>{checkoutData?.is_home_cleaning?t('service_details'):t('service_details_non_cleaning')}</p> 
                <ul>
                <li>  
                  {t('frequency')} <span>{checkoutData.frequency_name}</span>
                </li>
                <li>  
                  {t('duration')} <span>{checkoutData.hours_count} {t('hours')}</span>
                </li>
                <li> 
                  {t('no_of_professionals')} <span>{checkoutData.professionals_count}</span>
                </li>
                <li> 
                  {t('material')} <span>{checkoutData.is_cleaning_materials ? t('yes') : t('no')}</span>
                </li>
                </ul>
              </div>  
              <div className="card-content ">
                <p>{t('date_and_time')}</p>
                <ul>
                  <li> 
                    {t('professional')} <span>
                    <div className="is-flex">
                        {(checkoutData.professional_full_name) ? checkoutData.professional_full_name : ''}
                        {(checkoutData.professional_full_name !== "Auto assign" && checkoutData.professional_full_name !== "" && checkoutData.professional_profile_image !== "") ? <img alt="" src={checkoutData.professional_profile_image} className="cleaner-image en" /> : ""}
                    </div>
                    </span>
                  </li>
                  <li>  
                    {t('date')} <span>{(checkoutData.appointment_date) ? moment(checkoutData.appointment_date, ["YYYY-MM-DD"]).format("dddd, MMMM DD") : ''}</span>
                  </li>
                  <li>   
                    {t('start_time')} <span>{(checkoutData.start_time_between) ? checkoutData.start_time_between : ''}</span>
                  </li>
                </ul>
              </div>
              <div className="card-content ">
                <p>{t('address')} </p>  
                {/* <ul>
                  <li>
                  {(checkoutData && checkoutData.address) ? checkoutData.address : ''} 
                  </li>
                </ul> */}
              </div>
              <div className="card-content ">
                <p>{t('price_details')}</p>   
                <ul>
                  <li>  
                    {t('price')} <span>{checkoutData.currency} {(checkoutData && checkoutData.price) ? checkoutData.price : 0}</span>
                  </li>
                  <li> 
                    {t('vat')} <span>{checkoutData.currency} {(checkoutData && checkoutData.vatPrice) ? checkoutData.vatPrice : 0}</span>
                  </li>
                  <li>  
                    {t('discount')} <span>-{checkoutData.currency} {(checkoutData && checkoutData.discountPrice) ? checkoutData.discountPrice : 0}</span>
                  </li>
                  <li>  
                    {t('total')} <span className="total">{checkoutData.currency} {(checkoutData && checkoutData.totalPrice) ? checkoutData.totalPrice : 0}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <AddressAddModal ref={setAddAddressRef} updateCheckoutData={updateCheckoutData} />
    </>
  );
};

export default NextBackButton;
