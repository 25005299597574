/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {useNavigate, Link, NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import jQuery from "jquery";
import Modal from "react-bootstrap/Modal";
import {
  createUpdateQnbCustomer,
  getRhValid,
  saveAddress
} from "../../../../helpers/helpers";
import {ModifiedToastContainer} from "../../../../components/ModifiedToastContainer";
import {customerCreditCardListsAction} from "../../../../actions/customerDetails";
import {getFreeCreditAction} from "../../../../actions/FreeCreditAction";
import Steps from "../components/Steps";
import CheckoutSummary from "../components/CheckoutSummary";
import Loader from "../../../../components/Loader";
import {applyVoucherCodeAction} from "../../../../actions/applyVoucherCodeAction";
import {unAuthAppointmentBooking} from "../../../../actions/appointmentAction";
import AddCardModal from "../../frequency/payment/AddCardModal";
import {getDiscount} from "../../../../helpers/bookingAPIsteps";
import PromoCode from "./PromoCode";

const Payment = ({cart, checkoutData, updateCheckoutData, setCheckoutData}) => {
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [addVoucherModal, setAddVoucherModal] = useState(false);
  const handleAddVoucherModalShow = () => setAddVoucherModal(true);
  const handleAddVoucherModalClose = () => setAddVoucherModal(false);
  const [addServiceModal, setAddServiceModal] = useState(false);
  const handleAddServiceModalShow = () => setAddServiceModal(true);
  const handleAddServiceModalClose = () => setAddServiceModal(false);
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const [freeCredit, setFreeCredit] = useState(0);
  const [wallet, setWallet] = useState(0);
  const [showbtn, setShowBtn] = useState(false);
  const showBtn = () => setShowBtn(!showbtn);
  const [num, setnum] = useState(1);
  const [isCashData, setIsCashData] = useState();
  const [rhCreditDetails, setRhCreditDetails] = useState([]);
  const [transaction, setTransaction] = useState("");
  const [isCashPrice, setIsCashPrice] = useState(0);
  const [checked, setChecked] = useState(false);
  const [walletCash, setWalletCash] = useState(0);
  const [walletStatus, setWalletStatus] = useState(false);
  const [addNewCard, setAddNewCard] = useState(false);
  const [checkedValue, setCheckedValue] = useState("");
  const [isVoucher, setIsVoucher] = useState(false);
  const handleAddNewCardClose = () => {
    dispatch(customerCreditCardListsAction());
    setAddNewCard(false);
  };
  const handelNewCardModel = () => {
    setAddNewCard(true);
  };
  async function validRhCredit() {
    let data = {
      service_id:
        checkoutData?.service_id.length > 0
          ? checkoutData?.isBookNow
            ? checkoutData?.service_id
            : checkoutData?.service_id[0]
          : checkoutData?.service_id[0]
    };
    await getRhValid(data)
      .then((res) => {
        setRhCreditDetails(res.data);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  }

  const getCleanerTimeAvailabilitySlot = useSelector(
    (state) => state.cleanerTimeAvailabilityReducer
  );
  const {available_time, selectedCleaner} = getCleanerTimeAvailabilitySlot;

  useEffect(() => {
    validRhCredit();
  }, []);

  const incNum = () => {
    setnum(num + 1);
  };

  const incMin = () => {
    if (num > 0) {
      setnum(num - 1);
      setShowBtn(!showbtn);
    }
  };
  async function unAuthUser() {
    let addressDetails = {
      customer_id: currentUser?._id,
      address_nickname: checkoutData.address_nickname,
      address: checkoutData.address,
      area: checkoutData.area,
      address_type: checkoutData.address_type,
      street: checkoutData.street,
      building: checkoutData.building,
      floor: checkoutData.floor,
      house_no: checkoutData.house_no,
      office_no: checkoutData.office_no,
      country_id: localStorage.getItem("userCountryId"),
      country_code: localStorage.getItem("userCountryCode"),
      mobile: checkoutData.mobile,
      landline_number: "9795984238",
      latitude: checkoutData.latitude,
      longitude: checkoutData.longitude,
      isDefault: true
    };

    let awaitUrl =
      process.env.REACT_APP_LOCAL_API_URL + "/customer/address/create";
    let res = await saveAddress(awaitUrl, addressDetails, "POST");
    let apiUrl =
      process.env.REACT_APP_LOCAL_API_URL + "/appointment/create/address";
    let data = {
      address_id: res.data.address._id,
      is_home_cleaning: checkoutData.is_home_cleaning,
      service_id: checkoutData.service_id,
      country_id: localStorage.getItem("userCountryId"),
      customer_id: currentUser?._id,
      appointment_process: 1
    };

    let createAppointment = await unAuthAppointmentBooking(apiUrl, data);
    // console.log("createAddress", createAppointment.data);
    let appointment_id = createAppointment.data.appointment._id;

    let apiUrl2 =
      process.env.REACT_APP_LOCAL_API_URL + "/appointment/create/service";

    let data2 = {
      ordered_item: checkoutData?.cart,
      service_category_id: [checkoutData?.cart[0]?.sub_service_id],
      service_package_id: [checkoutData?.cart[0]?._id],
      appointment_process: 2,

      appointment_id: appointment_id,
      customer_id: currentUser?._id,
      totalAmount: checkoutData?.totalPrice
    };
    let serviceData = await unAuthAppointmentBooking(apiUrl2, data2);
    let apiUrl3 =
      process.env.REACT_APP_LOCAL_API_URL + "/appointment/create/time";
    let data3 = {
      appointment_date_start: checkoutData.appointment_date_start,
      start_time_between: checkoutData.start_time_between,
      appointment_process: 3,
      hours_count: checkoutData.hours_count,
      is_auto_assign: true,
      address_id: res.data.address._id,
      appointment_id: appointment_id,
      address_id: res.data.address._id,
      cleaner_id: selectedCleaner ? selectedCleaner : ""
      // professionalCount: checkoutData.professionals_count || 1,
      // cleaner_id: !checkoutData.is_auto_assign ? checkoutData?.cleaner_id : "",
    };
    await unAuthAppointmentBooking(apiUrl3, data3);
  }

  async function unAuthSaveUserData() {
    // console.log(1111, checkoutData.appointment_id);
    if (checkoutData.appointment_id === "") {
      // console.log(2222);
      await unAuthUser();
    }
  }

  useEffect(() => {
    unAuthSaveUserData();
  }, []);

  const [promoCodeData, setPromoCodeData] = useState({
    promo_code: localStorage.getItem("couponCode")
      ? localStorage.getItem("couponCode")
      : "",
    service_id: localStorage.getItem("frequencyTypeServiceDetailId"),
    min_amount: parseInt(checkoutData?.price),
    customer_id: currentUser?._id
  });

  const updatePromoCodeData = (key, value) => {
    setPromoCodeData((prevState) => ({
      ...prevState,
      [key]: value
    }));
  };
  const frequencyCheckoutData =
    localStorage.getItem("frequencyCheckoutData") &&
    localStorage.getItem("frequencyCheckoutData") !== "null"
      ? JSON.parse(localStorage.getItem("frequencyCheckoutData"))
      : false;
  const getCustomerAllCreditCardState = useSelector(
    (state) => state.customerDetailsReducer
  );
  const {loading, customerCreditCards} = getCustomerAllCreditCardState;
  console.log(loading,customerCreditCards)
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/payment/instruments";
  useEffect(() => {
    dispatch(customerCreditCardListsAction());
  }, [listUrl, dispatch]);

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const getFreeCrDataState = useSelector((state) => state.getFreeCreditReducer);
  const {free_cr_data} = getFreeCrDataState;

  let creditlistUrl = baseUrl + "/freeCredit/rh/list";
  useEffect(() => {
    localStorage.setItem("discoverBookingPage", window.location.href);
    dispatch(getFreeCreditAction(creditlistUrl, {}));
  }, [creditlistUrl]);

  useEffect(() => {
    const countryList = getAllCountryState?.country_list?.filter(
      (country) => country.id === checkoutData?.country_id
    );
    if (countryList) {
      setIsCashData(countryList[0]);
    }
  }, [getAllCountryState]);

  const cardredirectUrl = new URL(
    process.env.REACT_APP_BASE_URL + "/frequency/checkout/details"
  );

  cardredirectUrl.searchParams.set("step", 4);

  const cardToken = params.get("cardToken");
  let totalPrice = parseInt(checkoutData.totalPrice);
  let totalRemainingSubscriptionCredits =
    rhCreditDetails?.totalRemainingRHCredits
      ? rhCreditDetails?.totalRemainingRHCredits
      : 0;

  let totalWalletAmount = rhCreditDetails.total_wallet_credits
    ? rhCreditDetails.total_wallet_credits
    : 0;
  let payment_mode = checkoutData.payment_mode;
  let isPageLoading = false;

  useEffect(() => {
    if (customerCreditCards && customerCreditCards !== null) {
      updateCheckoutData("payment_mode", "Card");
      jQuery(".useCardPayment").prop("checked", true);
    }
    jQuery(".usermycredit").prop("checked", false);
  }, [customerCreditCards]);

  useEffect(() => {
    if (customerCreditCards) {
      updateCheckoutData("source_id", customerCreditCards?.id);
    }
  }, [customerCreditCards]);

  async function clearPromoCode() {
    updateCheckoutData("promo_code", "");
    updateCheckoutData("discountPrice", 0);
    updateCheckoutData("voucherDiscount", 0);
    updateCheckoutData("discount_type", "");
  }
  useEffect(() => {
    if (customerCreditCards) {
      updateCheckoutData("qnb_customer_id", "qnb_customer_id");
    }
  }, [loading, customerCreditCards]);

  async function myPaymentMode(mode, status) {
    let actionSuccess = true;
    if (totalPrice >= 150) {
      if (mode === "PaymentByCredit" && status) {
        if (rhCreditDetails.isRhValid === true) {
          updateCheckoutData("discount_type", "FreeCredit");
          jQuery("." + mode).prop("checked", true);
          totalRemainingSubscriptionCredits =
            totalRemainingSubscriptionCredits - 150;
          updateCheckoutData("rh_discount", 150);
          setFreeCredit(totalRemainingSubscriptionCredits);
          checkoutData.totalPrice =
            checkoutData.totalPrice - checkoutData.rh_discount;
          updateCheckoutData("totalPrice", checkoutData.totalPrice);
          updateCheckoutData("payment_mode", "online");
          updateCheckoutData("pay_type", "online");
          updateCheckoutData("rhClub", true);
        } else {
          jQuery("." + mode).prop("checked", false);
          updateCheckoutData("rhClub", rhCreditDetails.isRhValid);
          updateCheckoutData("discount_type", "");
        }
      }
      // if (mode === "PaymentByCash") {
      //   if (status) {
      //     updateCheckoutData("payment_mode", "cod");
      //     updateCheckoutData("pay_type", "cod");
      //   } else {
      //     updateCheckoutData("payment_mode", "");
      //     updateCheckoutData("pay_type", "");
      //   }
      // }

      if (mode === "PaymentByCard" && !status) {
        updateCheckoutData("payment_mode", "online");
        updateCheckoutData("pay_type", "online");
        jQuery("." + mode).prop("checked", true);
        actionSuccess = false;
      }

      if (mode === "PaymentByWallet" && status) {
        if (
          checkoutData.totalPrice > totalWalletAmount &&
          totalWalletAmount > 0
        ) {
          jQuery("." + mode).prop("checked", true);
          checkoutData.totalPrice =
            checkoutData.totalPrice - checkoutData.totalPrice;
          updateCheckoutData("isWallet", true);
          updateCheckoutData("payment_mode", "online");
          updateCheckoutData("pay_type", "online");
          updateCheckoutData("totalPrice", checkoutData.totalPrice);
          updateCheckoutData("wallet_amount", totalWalletAmount);
          setWallet(checkoutData.totalPrice);
        } else {
          setWalletStatus(false);
          setWallet(totalWalletAmount);
          updateCheckoutData("wallet_amount", 0);
          updateCheckoutData("isWallet", false);
          if (checkoutData.price === 0) {
            updateCheckoutData("price", checkoutData.totalPrice);
          } else {
            let price = checkoutData.price + totalWalletAmount;
            updateCheckoutData("price", checkoutData.price);
          }
        }
      }

      if (
        !actionSuccess &&
        jQuery(".Toastify__toast-container")[0] === undefined
      ) {
        toast.error(t("credit_balance_is_low"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    } else {
      if (mode === "PaymentByCard") {
        if (status) {
          updateCheckoutData("payment_mode", "Card");
        } else {
          updateCheckoutData("payment_mode", "");
        }
      }

      if (mode === "PaymentByWallet" && status) {
        jQuery("." + mode).prop("checked", true);
        updateCheckoutData("isWallet", true);
        updateCheckoutData("payment_mode", "online");
        updateCheckoutData("pay_type", "online");
        updateCheckoutData("priceAmount", checkoutData.pirce);
        // updateCheckoutData("wallet_amount", totalWalletAmount);
        if (totalWalletAmount > checkoutData.price) {
          totalWalletAmount = totalWalletAmount - checkoutData.totalPrice;
          setWallet(totalWalletAmount);
          setWalletStatus(true);
          updateCheckoutData("wallet_amount", checkoutData.totalPrice);

          // updateCheckoutData("totalPrice", 0);
          updateCheckoutData("price", 0);
        } else {
          // console.log(2222);
          // console.log(checkoutData?.price, checkoutData?.totalPrice, 5555);
          let price = checkoutData.price - totalWalletAmount;
          updateCheckoutData("wallet_amount", totalWalletAmount);
          updateCheckoutData("totalPrice", price);
          updateCheckoutData("price", checkoutData.price);
          // console.log(checkoutData?.price, checkoutData?.totalPrice, 4444);
          totalWalletAmount = 0;
          setWallet(0);
        }
      }
      if (mode === "PaymentByWallet" && !status) {
        setWalletStatus(false);
        setWallet(totalWalletAmount);
        updateCheckoutData("wallet_amount", 0);
        updateCheckoutData("isWallet", false);
        if (checkoutData.price === 0) {
          updateCheckoutData("price", checkoutData.totalPrice);
        } else {
          updateCheckoutData("price", checkoutData.price);
        }
      }

      if (mode === "PaymentByCredit") {
        let discount_type = "";
        if (status) {
          discount_type = "FreeCredit";
        }
      }
    }

    setTimeout(() => {
      updateCheckoutData("promo_code", "");
      updateCheckoutData("discountPrice", 0);
      updateCheckoutData("voucher", "");
    }, 2000);
  }

  async function updateDiscountType(discount_type) {
    updateCheckoutData("discount_type", discount_type);
  }

  // async function clearPromoCode() {
  //   updateCheckoutData("promo_code", "");
  //   updateCheckoutData("discountPrice", 0);
  //   updateCheckoutData("discount_type", "");
  // }

  // async function applyVoucher() {
  //   let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  //   const actionUrl = baseUrl + `/discount/apply/voucher`;
  //   updateCheckoutData("promo_code", promoCodeData.promo_code);
  //   dispatch(
  //     applyVoucherCodeAction(
  //       actionUrl,
  //       promoCodeData,
  //       handleAddVoucherModalClose,
  //       updateCheckoutData
  //     )
  //   );
  // }
  async function applyVoucher() {
    let baseUrl = process.env.REACT_APP_LOCAL_API_URL;

    const actionUrl = baseUrl + `/discount/apply/voucher`;

    let code = promoCodeData?.promo_code ? promoCodeData?.promo_code : "";
    const data = {
      promo_code: promoCodeData?.promo_code,
      service_id: checkoutData?.service_id,
      country_id: checkoutData?.country_id,
      customer_id: currentUser?.id
        ? currentUser?.id
        : checkoutData?.customer_id,
      min_amount: checkoutData?.price
    };
    const DiscountData = await getDiscount(actionUrl, data);
    if (DiscountData === "Voucher not found") {
      updateCheckoutData("promo_code", "");
      updatePromoCodeData("promo_code", "");
      updateCheckoutData("discount_type", "");
      toast.error("Voucher not found", {
        duration: 2000,
        position: "top-right"
      });

      handleAddVoucherModalClose();
    } else if (DiscountData === " This Voucher is Expire") {
      updateCheckoutData("promo_code", "");
      updatePromoCodeData("promo_code", "");
      updateCheckoutData("discount_type", "");
      toast.error("This Voucher is Expire", {
        duration: 2000,
        position: "top-right"
      });

      handleAddVoucherModalClose();
    }

    if (DiscountData?.code === 200) {
      updateCheckoutData("promo_code", promoCodeData?.promo_code);
      setCheckoutData((prevState) => ({
        ...prevState,
        ["discount_type"]:
          DiscountData?.coupon_type === "promo" ? "PromoCode" : "Birthday"
      }));
      setCheckoutData((prevState) => ({
        ...prevState,
        ["voucher"]: DiscountData?.voucher
      }));
      updateCheckoutData(
        "discount_type ",
        DiscountData?.coupon_type === "promo"
          ? "PromoCode"
          : DiscountData?.coupon_type === "birthday"
          ? "Birthday"
          : "Referral"
      );
      updateCheckoutData("voucherDiscount", DiscountData?.discount_price);
      // updateCheckoutData("discount_type",  DiscountData?.voucher);
      if (checkoutData?.isWallet) {
        setWallet(wallet + DiscountData?.discount_price);
      }

      setCheckoutData((prevState) => ({
        ...prevState,
        ["total_discount"]: checkoutData?.total_discount
          ? checkoutData?.total_discount + DiscountData?.discount_price
          : DiscountData?.discount_price
      }));
      handleAddVoucherModalClose();
    }

    //  updateCheckoutData("discount_type", "Birthday");
  }
  const handleChange = (e) => {
    e.preventDefault();
    if (checkoutData.page === 3) {
      setChecked(!checked);
      updateCheckoutData("payment_terms_condition", !checked);
    } else {
      setChecked(checked);
      updateCheckoutData("payment_terms_condition", checked);
    }
  };

  const letterChange = (e) => {
    if (e.currentTarget.value === "cash") {
      if (isCashData.isCash && isCashData.price > 0) {
        let price = isCashPrice;
        price =
          checkoutData.totalPrice + isCashData.price;
        updateCheckoutData("price", price);
        updateCheckoutData("totalPrice", price);
        setIsCashPrice(isCashData.price);
        setCheckedValue("cash");
      }
      setTransaction(e.currentTarget.value);
      updateCheckoutData("payment_mode", "cod");
      updateCheckoutData("pay_type", "cod");
    } else {
      let price = isCashPrice;
      price = checkoutData.price - price;
      setCheckedValue("card");
      updateCheckoutData("payment_mode", "card");
      updateCheckoutData("pay_type", "card");
      updateCheckoutData("price", price);
      updateCheckoutData("totalPrice", price);
      setIsCashPrice(0);
    }
  };

  let script = document.createElement("script");
  script.src = "https://www.simplify.com/commerce/simplify.pay.js";
  document.getElementsByTagName("head")[0].appendChild(script);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!customerCreditCards || customerCreditCards.length === 0) {
    return <p>No credit card details available.</p>;
  }

  return (
    <>
      <div className="">
        <Steps checkoutData={checkoutData} />
      </div>
      <ModifiedToastContainer />

      <div className="stepsContent Payment  ">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="card">
              <div className="stepsCard">
                <div className="checkboxSec">
                  {rhCreditDetails.isRhValid === true ? (
                    <div className="checkboxItem">
                      <div className="form-check switchBtn">
                        <div className="switchFlex">
                          <label className="form-check-label" htmlFor="radio3">
                            <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/card.png.webp" />
                            {t("use_credit_first")}{" "}
                            <span>
                              {freeCredit > 0
                                ? freeCredit
                                : totalRemainingSubscriptionCredits}
                            </span>
                          </label>
                          <div className="useMyCredit">
                            <label className="switch">
                              <input
                                type="checkbox"
                                className="usermycredit PaymentByCredit"
                                onChange={(event) =>
                                  event.target.checked
                                    ? myPaymentMode("PaymentByCredit", true)
                                    : myPaymentMode("PaymentByCredit", false)
                                }
                                disabled={
                                  free_cr_data?.totalRemainingRHCredits === 0
                                }
                              />
                              <span className="switchslide round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <p>
                        <Link
                          to={"/my-account/creditdetails"}
                          target="_blank"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {t("see_details1")}
                        </Link>
                      </p>
                    </div>
                  ) : (
                    <center></center>
                  )}
                  <div className="checkboxItem">
                    <div className="form-check switchBtn">
                      <div className="switchFlex">
                        <label className="form-check-label" htmlFor="radio3">
                          <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/card.png.webp" />
                          {t("use_wallet")} <span>{wallet}</span>
                        </label>
                        <div className="useMyCredit">
                          <label className="switch">
                            <input
                              type="checkbox"
                              className="usermycredit PaymentByWallet"
                              onChange={(event) =>
                                event.target.checked
                                  ? myPaymentMode("PaymentByWallet", true)
                                  : myPaymentMode("PaymentByWallet", false)
                              }
                              disabled={totalWalletAmount === 0}
                            />
                            <span className="switchslide round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <p>
                      <Link
                        to={"/my-account/righthandwallet"}
                        target="_blank"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        {t("see_details1")}
                      </Link>
                    </p>
                  </div>
                  <div className="checkboxItem payBYcredit">
                    <h3 className="mb-4">{t("pay_by_cc")}</h3>
                      {customerCreditCards.map((cc) => (
                      <div className="form-check" key={cc?.token}>
                        <input
                          type="radio"
                          id={cc?.token}
                          className="form-check-input PaymentByCard"
                          value="credit"
                          disabled={walletStatus}
                          name="radioButton"
                          onChange={letterChange}
                        />
                        <div>
                          <label className="form-check-label" htmlFor={cc?.token}>
                            ...{cc?.card?.cardNumber}
                          </label>
                          <p>
                            {cc?.card?.cardExpiry}
                          </p>
                        </div>
                      </div>
                    ))}
    
                    {!customerCreditCards && (
                      <div className="cardsec">
                        <h3 onClick={() => handelNewCardModel()}>
                          {t("add_new_card")}
                          <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/plus2.png.webp" />
                        </h3>
                      </div>
                    )}

                    {isCashData?.isCash && (
                      <>
                        <h3 className="mb-4">{t("pay_by_cash")}</h3>
                        <div className="form-check">
                          {/* <input
                            type="radio"
                            className="form-check-input useCardPayment PaymentByCard"
                            name="optradio"
                            value="cash"
                            onChange={
                              (event)=>handleChage(event.target.value)
                            }
                            defaultChecked={
                              checkoutData.payment_mode === "Card"
                             
                            }
                          /> */}

                          <input
                            type="radio"
                            className="form-check-input  PaymentByCard"
                            value="cash"
                            defaultChecked={transaction === "cash"}
                            name="radioButton"
                            onChange={letterChange}
                            disabled={walletStatus}
                          />
                          <div>
                            <label className="form-check-label line-height-0">
                              {t("pay_by_cash")}{" "}
                              <span>
                                {" "}
                                ({t("Pay_extra_amount")} {isCashData?.currency}{" "}
                                {isCashData.price}){" "}
                              </span>
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {/* <div className="checkboxItem">
                    <div className="form-check">
                      {(customerCreditCards && customerCreditCards !== null) ? 
                      (<div>
                        <input
                        type="checkbox"
                        className="form-check-input useCardPayment PaymentByCard"
                        id="radio3"
                        name="optradio"
                        onChange={(event) => (event.target.checked) ? myPaymentMode("PaymentByCard",true) : myPaymentMode("PaymentByCard",false) } 
                        defaultChecked={checkoutData.payment_mode === "Card"}
                      />
                      <label className="form-check-label" htmlFor="radio3">
                        {t('pay_by_cc')} ...{customerCreditCards.last4}
                      </label></div>) : ''
                    }

                    </div>
                    <div className="cardsec">
                      <h3>
                       {t('add_new_card')}
                        <button
                          className="finalPaymentButton"
                          data-sc-key={process.env.REACT_APP_QNB_PUBLIC_KEY} 
                          data-name="Right Hands"
                          data-description="Complete peace of mind" 
                          data-receipt="true" 
                          data-logo="https://dvdljkkxpxqo3.cloudfront.net/images/logo.png.webp" 
                          data-operation="create.token" 
                          id="sc_btn_1649745021579"
                          data-redirect-url={process.env.REACT_APP_BASE_URL+"/service/checkout/details?step=3"}
                          type="button">
                          <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/plus2.png.webp" />
                        </button>
                      </h3>
                    </div>
                  </div>    */}
                  {<div className="checkboxItem">
                    {(() => {
                      if (
                        checkoutData.promo_code !== "" &&
                        checkoutData.voucherDiscount > 0
                      ) {
                        return (
                          <div className="applyCoupon">
                            <p
                              className="cancelPromoCode"
                              onClick={() => clearPromoCode()}
                            >
                              <i className="fa fa-times"></i>
                            </p>
                            <h4>{t("voucher_applied")}</h4>
                            <h3>
                              {checkoutData.promo_code}{" "}
                              <span>
                                {checkoutData?.currency}{" "}
                                {checkoutData.voucherDiscount} {t("off")}
                              </span>
                            </h3>
                          </div>
                        );
                      } else {
                        return (
                          <button
                            className="addVoucher"
                            onClick={() => handleAddVoucherModalShow()}
                          >
                            {t("add_voucher_code")}{" "}
                            <i className="fa fa-angle-right"></i>
                          </button>
                        );
                      }
                    })()}
                  </div>}
                  <div className="checkboxItem paymentLabel ">
                    <label>
                      <input
                        type="checkbox"
                        className="me-2 "
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          updateCheckoutData(
                            "payment_terms_condition",
                            e.target.checked
                          );
                        }}
                      />
                      {t("understand_that_purchasing")}{" "}
                      <Link to={"/terms/conditions"} target="_blank">
                        {t("terms_of_use")}
                      </Link>{" "}
                      {t("and")}{" "}
                      <Link to={"/cancellation/policy"} target="_blank">
                        {t("cancellation_policy")}
                      </Link>{" "}
                      {t("my_payment_method_charged_before_service")}
                    </label>
                  </div>
                  <div className="checkboxItem paymentLabel ">
                    {/* {console.log("currentUser", currentUser)} */}
                    {!currentUser.isRhMember &&
                    rhCreditDetails.isRhValid === false ? (
                      <p>
                        <Link
                          to={"/rh-club/policy"}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {t("Start saving by joing the RH club")}
                        </Link>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5">
            <CheckoutSummary
              checkoutData={checkoutData}
              cart={cart}
              updateCheckoutData={updateCheckoutData}
            />
          </div>
          {loading ? <Loader /> : ""}
        </div>
      </div>
      <Modal
        show={addVoucherModal}
        onHide={handleAddVoucherModalClose}
        id="addVocher"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {t("add_voucher_code")}
            <i className="flag flag-us"></i>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <div className="inputdiv col-lg-12 ">
              <label>{t("voucher_code")}</label>
              <input
                type="nubmer"
                className="form-control rds-8 h-47"
                placeholder=""
                defaultValue={
                  promoCodeData.promo_code ? promoCodeData.promo_code : ""
                }
                onChange={(event) => {
                  updatePromoCodeData("promo_code", event.target.value);
                }}
              />
            </div>
            <div className="row">
              <div className="w-full">
                <button
                  className={
                    "btn btn-primary mt-3 w-full " +
                    (promoCodeData.promo_code ? "" : "disabled")
                  }
                  onClick={() => applyVoucher()}
                  disabled={promoCodeData.promo_code ? null : "disabled"}
                >
                  {t("apply")}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={addNewCard}
        onHide={() => setAddNewCard(false)}
        id="addVocher"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {t("add_new_card")}
            <i className="flag flag-us"></i>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddCardModal handleAddNewCardClose={handleAddNewCardClose} />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Payment;
